import { useMemo } from 'react';
import { TimeRange, TimeRangeRow } from './TimeRangeRow';
import { Timestamp } from 'firebase/firestore/lite';
import classes from './DayRow.module.css';


export type DayRanges<T extends Date | Timestamp> = { dayRanges: TimeRange<T>[] }; // BUGFIX: we use dictionary with `dayRanges` key instead of direct array because firestore doesn't support 2d arrays


type Props = {
  title: string;
  ranges: DayRanges<Date>;
  setRanges: (val: DayRanges<Date>) => void;
}


export function DayRow({ title, ranges, setRanges }: Props) {

  const data = useMemo(() => ranges.dayRanges.length
    ? ranges.dayRanges
    : [{ start: undefined, end: undefined }]
  , [ranges]);

  function handleSetRanges(ranges: DayRanges<Date>) {
    setRanges(ranges);
  }

  function handleSetRange(index: number, range: TimeRange<Date>) {
    const res = [...ranges.dayRanges];
    res.splice(index, 1, range);
    handleSetRanges({ dayRanges: res });
  }

  return (<>
    <div className={classes.title}>{title}</div>
    {data.map((range, index) =>
      <TimeRangeRow
        key={index}
        range={range}
        setRange={range => handleSetRange(index, range)}
      />
    )}
  </>);
}
