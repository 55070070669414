import { Firestore } from 'firebase/firestore/lite';
import { MatchStatus } from './job';


export async function getAllMatches(db: Firestore): Promise<MatchStatus[]> {
  // const matches = (await getDocs(collection(db, 'guRefMatchStatuses'))).docs;
  // return matches.map(doc => ({ name: doc.get('name') as string, uid: doc.id }));

  return [
    {
      "name": "Employer Not Interested",
      "uid": "0fIpmYwnIoVUgE46nEBs"
    },
    {
      "name": "Both Not Interested",
      "uid": "3J05zFMYO6WCfHcUvy4e"
    },
    {
      "name": "Candidate Not Interested",
      "uid": "ANNBwAbe6E7g2gJIUxKo"
    },
    {
      "name": "Both Interested",
      "uid": "LfpxM8gv4jeJCtKDlREs"
    },
    {
      "name": "Candidate Interested",
      "uid": "PGzAf8boNtF2ToJtWUK0"
    },
    {
      "name": "Candidate Interested, Employer Not Interested",
      "uid": "pP8zCkC17HB45BAKgTXa"
    },
    {
      "name": "Employer Interested",
      "uid": "q2k0HYF91ibozhUAYSPA"
    },
    {
      "name": "Employer Interested, Candidate Not Interested",
      "uid": "ybb6cZuW7pXvSXFYcIkE"
    }
  ];
}
