import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import classes from './BaseJobCandidatesScreen.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Candidate } from 'modules/candidate/services/candidate';
import { Action, DataTable } from 'modules/shared/components/DataTable/DataTable';
import { HeadCell } from 'modules/shared/components/DataTable/interfaces';


export interface GridRow {
  raw: Candidate;
  name: string;
  location: string;
  startDate: string;
  endDate: string;
  minHourlyRate: string;
}


type Props = {
  candidates: Candidate[];
  onClick?: (candidate: Candidate) => void;
  actions?: Action<GridRow>[];
  matched?: Candidate[];
  setMatched?: (val: Candidate[]) => void;
}


const headCells: readonly HeadCell<GridRow, keyof GridRow>[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Name',
    sortable: true,
  },
  {
    id: 'location',
    numeric: true,
    label: 'Location',
    sortable: true,
    sortValue: row => row.raw.homeAddress.city
  },
  {
    id: 'startDate',
    numeric: true,
    label: 'Start Date',
    sortable: true,
    sortValue: row => row.raw.firstDay.toMillis()
  },
  {
    id: 'endDate',
    numeric: true,
    label: 'End Date',
    sortable: true,
    sortValue: row => row.raw.lastDay?.toMillis() ?? Number.MAX_SAFE_INTEGER
  },
  {
    id: 'minHourlyRate',
    numeric: true,
    label: 'Min. Hourly Rate',
    sortValue: row => row.raw.desiredWage
  },
];


export function BaseJobCandidatesScreen({ candidates, onClick, actions, setMatched, matched }: Props) {
  const [rows, setRows] = useState<GridRow[]>([]);

  function handleCandidateClick({ raw: candidate }: GridRow) {
    onClick?.(candidate);
  }

  // fill rows for the table
  useEffect(() => {
    candidates && setRows(candidates
      .filter(c => c.isOnboarded)
      .map(c => ({
          raw: c,
          name: c.firstName + ' ' + c.lastName,
          location: c.homeAddress.city + ', ' + c.homeAddress.state,
          startDate: c.firstDay.toDate().toLocaleDateString('en-US'),
          endDate: c.lastDay?.toDate().toLocaleDateString('en-US') ?? 'N/A',
          minHourlyRate: '$' + c.desiredWage + '/hr',
        })
      )
    )}, [candidates]);

  const selected = useMemo(() => {
    if (matched) {
      const ids = matched.map(m => m.uid);
      return rows.filter(row => ids.includes(row.raw.uid))
    }
    return [];
  }, [matched]);

  function setSelected(selected: GridRow[]) {
    setMatched!(selected.map(s => s.raw));
  }

  return(
    <div className={classes.container}>

      <div className={classes.header}>
        <Typography variant='h4' sx={{color: 'mainBackground.contrastText'}}>Available Candidates</Typography>
        { !candidates && <CircularProgress className={classes.progress} size={30}/> }
      </div>

      <DataTable
        actions={actions}
        data={rows}
        selectable={!!setMatched}
        headCells={headCells}
        initialOrderBy='startDate'
        keyField={row => row.raw.uid}
        onRowClick={handleCandidateClick}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
}
