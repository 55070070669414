import MobileTimePicker from '@mui/lab/MobileTimePicker';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';


type Props = {
  onChange: (val: Date | undefined) => void;
  value?: Date;
  label: string;
};


export function TimeField({ value, label, onChange }: Props) {
  return (
  <MobileTimePicker
    label={label}
    clearable
    minutesStep={30}
    value={value ?? null}
    onChange={() => {}}
    onAccept={val => onChange(val || undefined)}
    renderInput={params => <TextField {...params} />}
    InputProps={{
      endAdornment: <InputAdornment position='end'>
        <IconButton
          aria-label='Clear'
          onClick={() => onChange(undefined)}
          edge='end'
        >
          <ClearIcon/>
        </IconButton>
      </InputAdornment>
    }}
    />
  );
}