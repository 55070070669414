import { useState } from 'react';
import { Job } from 'modules/jobs/services/job';
import { BaseCurrentJobsScreen } from './BaseCurrentJobsScreen';


export function CurrentJobsEmployerScreen() {
  const [jobs, setJobs] = useState<Job[]>([]);

  return(
    <BaseCurrentJobsScreen jobs={jobs} setJobs={setJobs}/>
  );
}
