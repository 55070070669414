import * as React from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { FirebaseApp, FirebaseError } from 'firebase/app';
import { getAuth, signOut, User } from '@firebase/auth';
import { getFirestore, collection, getDocs, query, where, CollectionReference, Firestore} from '@firebase/firestore/lite';
import { useFirebase } from 'modules/shared/services/firebase.service';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore/lite';


type AuthContextType = {
  user?: User, // firebase user
  isReady: boolean, // firebase app initialization status
  wasRegistered: (phone: string) => Promise<boolean>;
  loginWithEmail: (email: string, password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  registerWithEmail: (email: string, password: string, firstname: string, lastname: string) => Promise<void>;
  logout: () => Promise<void>;
};


export type AuthContextTypeWithUser = Omit<AuthContextType, 'user'> & { user: User };


const AuthContext = createContext<AuthContextType>(null!);


async function loginWithEmail(app: FirebaseApp, email: string, password: string) {
  await signInWithEmailAndPassword(getAuth(app), email, password)
    .catch((e: FirebaseError) => {
      console.log(e.message);
      alert('Your credencials are not valid');
    });
}

async function resetPassword(app: FirebaseApp, email: string) {
  await sendPasswordResetEmail(getAuth(app), email, { url: window.location.origin + '/login' })
  .catch((e: FirebaseError) => console.log(e.message));
}


async function registerWithEmail(app: FirebaseApp, email: string, password: string, firstname: string, lastname: string) {
  try {
    const { user } = await createUserWithEmailAndPassword(getAuth(app), email, password);

    // create blank user doc with logged user uid in guUsers
    const userRef = doc(collection(getFirestore(app), 'guEmployerUsers'), user.uid);
    await setDoc(userRef, { uid: user.uid, email, firstname, lastname, role: 'regular' });
  }
  catch (e) {
    e instanceof FirebaseError
      ? alert(e.message)
      : console.log('generic error', e);
  }
}


async function wasRegistered(db: Firestore, phone: string): Promise<boolean> {
  const q = query(
    collection(db, 'guUsers') as CollectionReference<{phoneNumber: string}>,
    where('phoneNumber', '==', phone)
  );
  const count = (await getDocs(q)).size;
  return !!count;
}


async function logout(app: FirebaseApp) {
  signOut(getAuth(app));
}


export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { firebaseApp, db } = useFirebase();
  const [user, setUser] = useState<User>();
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {

    // on auth state change update `user` state. When user logs out navigation will navigate to welcome page
    const unsub = getAuth(firebaseApp)
      .onAuthStateChanged(user => {
        setUser(user ?? undefined);
        setIsReady(true);
      });

    return () => unsub(); // unsubscribe from `onAuthStateChanged` event on destroy
  }, [firebaseApp]);

  const context = useMemo<AuthContextType>(() => ({
    user,
    isReady,
    loginWithEmail: async (...rest) => loginWithEmail(firebaseApp, ...rest),
    resetPassword: async (email) => resetPassword(firebaseApp, email),
    registerWithEmail: async (...rest) => registerWithEmail(firebaseApp, ...rest),
    wasRegistered: (phone) => wasRegistered(db, phone),
    logout: async () => logout(firebaseApp),
  }), [firebaseApp, user, isReady]);

  return isReady ? <AuthContext.Provider value={context}>{children}</AuthContext.Provider> : <></>;
}


export function useAuth() {
  return useContext(AuthContext);
}


export function useDefinedAuth() {
  return useContext(AuthContext) as AuthContextTypeWithUser;
}
