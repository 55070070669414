/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  firebaseConfig: {
    apiKey: "AIzaSyC7H_Yh1uHKHqPdE72Umj54VXBYYOjOi88",
    authDomain: "gigajobs-13346.firebaseapp.com",
    projectId: "gigajobs-13346",
    storageBucket: "gigajobs-13346.appspot.com",
    messagingSenderId: "588295349006",
    appId: "1:588295349006:web:4324fa87f75f4d94b0ad5f",
  }
};