import { DayRanges, DayRow } from './DayRow';
import { Timestamp } from 'firebase/firestore/lite';
import classes from './JobTimeslots.module.css';


export type WeekRanges<T extends Date | Timestamp> = [DayRanges<T>, DayRanges<T>, DayRanges<T>, DayRanges<T>, DayRanges<T>, DayRanges<T>, DayRanges<T>];


type Props = {
  weekRanges: WeekRanges<Date>;
  setWeekRanges: (val: WeekRanges<Date>) => void;
}


const WEEK_DAYS = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
export type TimeRange<T extends Date | Timestamp> = { start: T | undefined, end: T | undefined };


export function JobTimeslots({ weekRanges, setWeekRanges }: Props) {

  function setDayRanges(index: number, ranges: DayRanges<Date>) {
    const newWeekRanges = [...weekRanges] as WeekRanges<Date>;
    newWeekRanges.splice(index, 1, ranges);
    setWeekRanges(newWeekRanges);
  }

  return (
    <div className={classes.container}>
      { WEEK_DAYS.map((day, i) =>
        <DayRow
          title={day}
          key={day}
          ranges={weekRanges[i]}
          setRanges={ranges => setDayRanges(i, ranges)}
        />
      )}
    </div>
  );
};
