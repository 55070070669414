import { Timestamp } from 'firebase/firestore/lite';
import classes from './TimeRangeRow.module.css';
import { useMemo } from 'react';
import { MS_DAY, MS_HOUR } from 'modules/shared/constants/date.constants';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import { TimeField } from '../TimeField';


export type TimeRange<T extends Date | Timestamp> = { start: T | undefined, end: T | undefined };

type Props = {
  range: TimeRange<Date>;
  setRange: (val: TimeRange<Date>) => void;
}


export function TimeRangeRow({ range, setRange }: Props) {
  // const hasError = useMemo(() => {
  //   const start = range.start && dayTime(range.start);
  //   const end = range.end && dayTime(range.end);
  //   return start !== undefined && end !== undefined && start + MS_HOUR > end;
  // }, [range.start, range.end]);

  const isOverNight = useMemo(() => range.start && range.end && range.end < range.start, [range]);
  const duration = useMemo(() => {
    if (!range.end || !range.start) return '';
    let diff = (+range.end % MS_DAY) - (+range.start % MS_DAY);
    if (diff <= 0) diff += MS_DAY;
    return Math.ceil(diff / MS_HOUR) + 'h';
  }, [range]);

  return (
    <>
      <TimeField
        label='Start'
        value={range.start}
        onChange={val => setRange({ start: val, end: range.end })}
      />

      <TimeField
        label='End'
        value={range.end}
        onChange={val => setRange({ start: range.start, end: val })}
      />

      <span className={classes.info}>{duration} { isOverNight && <Brightness3Icon sx={{ fontSize: 20 }}/> }</span>
    </>
  );
}
