import * as React from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { FirebaseApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, query, where, doc, DocumentReference } from '@firebase/firestore/lite';
import { useFirebase } from 'modules/shared/services/firebase.service';
import { useDefinedAuth } from 'modules/auth/services/auth.service';
import { Industry, Jobtype } from 'modules/shared/services/gigup';
import { useGigup } from 'modules/shared/services/gigup.service';
import { Boost, Trait, BoostDto, Candidate, CandidateDto } from './candidate';
import { getAge } from './helpers/getAge.fn';
import { formattedTimeRange } from './helpers/formattedTimeRange.fn';
import { loadImage } from 'modules/shared/helpers/loadImage.fn';
import { Firestore } from 'firebase/firestore/lite';


type CandidateContextType = {
  candidate?: Candidate;
  boosts: Boost[];
  getCandidate: (id: string) => Promise<Candidate>;
  getBoosts: () => Promise<Boost[] | undefined>;
  getBoostsTraits: () => Trait[] | undefined;
  getCandidateAvailabilities: () => string[] | undefined;
  loadAvatar: () => Promise<string>;
};


const CandidateContext = createContext<CandidateContextType>(null!);


async function getCandidate(db: Firestore, candidateId: string, allIndustries: Industry[], allJobtypes: Jobtype[]): Promise<Candidate> {
  const docData = await getDoc(doc(db, 'guUsers/' + candidateId) as DocumentReference<CandidateDto>);
  const data = docData.data()!;
  return { ...data, birthday: data.birthday.toDate(), preferredIndustries: getIndustries(allIndustries, data)!, preferredJobtypes: getJobtypes(allJobtypes, data)! };
}


function getIndustries(allIndustries: Industry[], candidate?: CandidateDto) {
  return candidate?.preferredIndustries.map(ref =>
    allIndustries.find(industry => industry.uid === ref.id)!.name
  );
}


function getJobtypes(allJobtypes: Jobtype[], profile?: CandidateDto) {
  return profile?.preferredJobtypes.map(ref =>
    allJobtypes.find(jobtype => jobtype.uid === ref.id)!.name
  );
}


function getCandidateAvailabilities(profile?: Candidate): string[] {
  return profile?.weekRanges.map(({ dayRanges }) =>
    dayRanges.length ? dayRanges.map(formattedTimeRange).join('\n') : 'N/A'
  ) ?? [];
}


async function getBoosts(app: FirebaseApp, candidate: Candidate): Promise<Boost[]>  {
  const db = getFirestore(app);
  const q = query(
    collection(db, 'guBoosts'),
    where('phoneNumber', '==', candidate.phoneNumber),
  );
  const docs = (await getDocs(q)).docs;

  return Promise.all(docs
    .map(d => d.data() as BoostDto)
    .filter(d => !d.isDeleted)
    .map(async (data) => {
      const booster = (await getDoc(doc(db, 'guUsers/' + data.boosterUid))).data();
      const name = booster?.firstName + ' ' +  booster?.lastName + ', ' + getAge(booster?.birthday);
      const traitName = (await getDoc(data.traits[0])).get('name') as string;

      return {
        imageURL: await loadImage(app, data.boosterUid),
        boosterUid: data.boosterUid,
        note: data.note,
        trait: traitName,
        name,
        phoneNumber: data.phoneNumber,
      };
    })
  );
}


function getBoostsTraits(boosts: Boost[]) {
  const dict = boosts
    .reduce<Record<string, number>>((res, { trait }) => (res[trait] = res[trait] + 1 || 1, res), {});

  return Object.keys(dict).map(name => ({ name, count: dict[name] }));
}


export function CandidateProvider({ children }: { children: React.ReactNode }) {
  const { firebaseApp, db } = useFirebase();
  const { user } = useDefinedAuth();
  const { allIndustries, allJobtypes } = useGigup();
  const [candidate, setCandidate] = useState<Candidate>();
  const [boosts, setBoosts] = useState<Boost[]>([]);
  const candidateRef = React.useRef<Candidate>(null!);

  const context = useMemo<CandidateContextType>(() => ({
    candidate,
    boosts,
    getCandidate: async (id) => {
      setCandidate(undefined);
      const candidate = await getCandidate(db, id, allIndustries, allJobtypes);
      candidateRef.current = candidate;
      setCandidate(candidate);
      return candidate;
    },
    loadAvatar: () => loadImage(firebaseApp, candidateRef.current.uid),
    getBoosts: async () => {
      setBoosts([]);
      const boosts = candidateRef ? await getBoosts(firebaseApp, candidateRef.current) : [];
      setBoosts(boosts);
      return boosts;
    },
    getBoostsTraits: () => getBoostsTraits(boosts),
    getCandidateAvailabilities: () => getCandidateAvailabilities(candidateRef.current),
  }), [user, firebaseApp, candidate, boosts, candidateRef]);

  return <CandidateContext.Provider value={context}>{children}</CandidateContext.Provider>;
}


export function useCandidate() {
  return useContext(CandidateContext);
}
