import { Button, Chip, CircularProgress, FormHelperText, Paper, TextField, Typography } from '@mui/material';
import classes from './JobEditor.module.css';
import { useTheme } from '@mui/system';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useGigup } from 'modules/shared/services/gigup.service';
import { Industry, Jobtype } from 'modules/shared/services/gigup';
import { Job } from 'modules/jobs/services/job';
import { useJobs } from 'modules/jobs/services/jobs.service';
import { Employer } from 'modules/employers/services/employer';
import { useEmployers } from 'modules/employers/services/employers.service';
import { JobTimeslots } from 'modules/jobs/screens/JobEditor/components/JobTimeslots';
import { WeekRanges } from 'modules/jobs/services/job';
import { DateField } from './components/DateField';


type IFormInputs = {
  title: string;
  description: string;
  firstDay: Date;
  lastDay?: Date;
  industries: Industry[];
  jobtypes: Jobtype[];
  location: string;
  maxHourlyWage: number;
  minHourlyWage: number;
  weekRanges: WeekRanges<Date>;
};


const initialWeekRanges: WeekRanges<Date> = [{ dayRanges: [] }, { dayRanges: [] }, { dayRanges: [] }, { dayRanges: [] }, { dayRanges: [] }, { dayRanges: [] }, { dayRanges: [] }];


const schema = yup.object({
  title: yup.string().required('Required'),
  description: yup.string().required('Required'),
  firstDay: yup.date().required('Required').typeError('Required'),
  location: yup.string().required('Required'),
  maxHourlyWage: yup.number().required('Required').typeError('Required'),
  minHourlyWage: yup.number().required('Required').typeError('Required'),
  industries: yup.array().required('Required').min(1, 'Please select at least one industry'),
  jobtypes: yup.array().required('Required').min(1, 'Please select at least one job type'),
}).required();


export function JobEditorScreen() {
  const { employerId, jobId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { allJobtypes, allIndustries } = useGigup();
  const { getEmployerById } = useEmployers();
  const { getJobById, addJob, updateJob } = useJobs();
  const [isBusy, setIsBusy] = useState(true);
  const { register, handleSubmit, control, reset, formState: { errors } } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });
  const title = useMemo(() => jobId ? 'Edit Job' : 'Create Job', [jobId]);
  const [job, setJob] = useState<Job>();
  const [employer, setEmployer] = useState<Employer>(undefined!);

  useEffect(() => {
    setIsBusy(true);
    getEmployerById(employerId!).then(em => setEmployer(em!));

    jobId
      ? getJobById(jobId).then(setJob)
      : reset();
  }, [getJobById, getEmployerById, employerId, jobId]);

  useEffect(() => {
    if ((!jobId || job) && employer) setIsBusy(false);
  }, [jobId, job, employer]);

  useEffect(() => {
    if (job) {
      const { ref, employer, ...data } = job; // exclude ref and employer reccurence
      reset(data);
    }
  }, [reset, job]);

  async function onSubmit(data: IFormInputs) {
    setIsBusy(true);
    jobId
      ? await updateJob(jobId, data)
      : await addJob({ ...data }, employer);
    setIsBusy(false);
    navigate(-1);
  }

  return (
    <div className={classes.container} style={{ padding: theme.spacing(4, 0, 2) }}>
      <div className={classes.content}>

        <div className={classes.header}>
          <Typography className='title' variant='h4' sx={{ color: 'mainBackground.contrastText' }}>{title}</Typography>
          {isBusy && <CircularProgress className={classes.progress} size={30} />}
        </div>

        <Paper
          sx={{ mt: 1, p: 4, display: 'flex', flexDirection: 'column' }}
          component='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            error={!!errors.title}
            helperText={errors.title?.message ?? ' '}
            {...register('title')}
            InputLabelProps={{ shrink: true }}
            margin='dense'
            fullWidth
            label='Title'
            placeholder='job title'
          />

          <TextField
            error={!!errors.description}
            helperText={errors.description?.message ?? ' '}
            {...register('description')}
            InputLabelProps={{ shrink: true }}
            margin='dense'
            fullWidth
            label='Description'
            placeholder='job description'
          />

          <TextField
            error={!!errors.location}
            helperText={errors.location?.message ?? ' '}
            {...register('location')}
            InputLabelProps={{ shrink: true }}
            margin='dense'
            fullWidth
            label='Location'
            placeholder='job location'
          />

          <div className={classes.wageBlock}>
            <TextField
              error={!!errors.minHourlyWage}
              helperText={errors.minHourlyWage?.message ?? ' '}
              {...register('minHourlyWage')}
              InputLabelProps={{ shrink: true }}
              margin='dense'
              fullWidth
              label='Min Hourly Wage'
              placeholder='min hourly wage'
            />

            <TextField
              error={!!errors.maxHourlyWage}
              helperText={errors.maxHourlyWage?.message ?? ' '}
              {...register('maxHourlyWage')}
              InputLabelProps={{ shrink: true }}
              margin='dense'
              fullWidth
              label='Max Hourly Wage'
              placeholder='max hourly wage'
            />
          </div>

          <div className={classes.availabilityBlock}>

            <DateField control={control} register={register}
              name='firstDay'
              label='First Day'
              error={!!errors.firstDay}
              helperText={errors.firstDay?.message ?? ' '}
            />

            <DateField control={control} register={register}
              name='lastDay'
              label='Last Day'
              clearable
              helperText='Long term if not defined'
            />

          </div>

          <Typography sx={{mt:1, mb:1}} variant='subtitle1'>Job Slots</Typography>

          <Controller
            name='weekRanges'
            control={control}
            defaultValue={job?.weekRanges ?? initialWeekRanges}
            render={({ field }) => <JobTimeslots weekRanges={field.value} setWeekRanges={field.onChange}/>}
          />

          <Typography
            className={classes.industriesTitle}
            variant='subtitle1'
            color={errors.industries && 'error'}
          >Select Industries</Typography>

          <div className={classes.pillsBlock}>
            <Controller
              name='industries'
              control={control}
              defaultValue={[]}
              render={({ field }) => <>{
                allIndustries.map(industry =>
                  <Chip
                    className={classes.pill}
                    label={industry.name}
                    key={industry.uid}
                    variant={errors.industries && 'outlined'}
                    onClick={() => {
                      const ind = field.value.findIndex(d => d.uid === industry.uid);
                      const res = [...field.value];
                      ind > -1 ? res.splice(ind, 1) : res.push(industry);
                      field.onChange(res);
                    }}
                    color={field.value.map(d => d.uid).includes(industry.uid) ? 'primary' : errors.industries ? 'error' : undefined}
                  />
                )
              }</>}
            />
            <FormHelperText sx={{ ml: 2 }} error>{(errors.industries as unknown as FieldError)?.message ?? ' '}</FormHelperText> {/* !!! errors.industries type is incorrectly set to Industry[] while really it is FieldError */}
          </div>

          <Typography variant='subtitle1' color={errors.jobtypes && 'error'}>Select Job Types</Typography>
          <div className={classes.pillsBlock}>
            <Controller
              name='jobtypes'
              control={control}
              defaultValue={[]}
              render={({ field }) => <>{
                allJobtypes.map(jobtype =>
                  <Chip
                    className={classes.pill}
                    label={jobtype.name}
                    key={jobtype.uid}
                    variant={errors.jobtypes && 'outlined'}
                    onClick={() => {
                      const ind = field.value.findIndex(d => d.uid === jobtype.uid);
                      const res = [...field.value];
                      ind > -1 ? res.splice(ind, 1) : res.push(jobtype);
                      field.onChange(res);
                    }}
                    color={field.value.map(d => d.uid).includes(jobtype.uid) ? 'primary' : errors.jobtypes ? 'error' : undefined}
                  />
                )
              }</>}
            />
            <FormHelperText sx={{ ml: 2 }} error>{(errors.jobtypes as unknown as FieldError)?.message ?? ' '}</FormHelperText> {/* !!! errors.jobtypes type is incorrectly set to Jobtype[] while really it is FieldError */}
          </div>

          <Button
            variant='contained'
            type='submit' sx={{ mt: 2, alignSelf: 'flex-end' }}
            className={classes.saveButton}
          >Save</Button>
        </Paper>
      </div>
    </div>
  )
}
