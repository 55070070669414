import { BaseJobCandidatesScreen, GridRow } from './BaseJobCandidatesScreen';
import { useNavigate, useParams } from 'react-router-dom';
import { Candidate } from 'modules/candidate/services/candidate';
import { useJobs } from 'modules/jobs/services/jobs.service';
import { useState, useEffect, useMemo } from 'react';
import { Action } from 'modules/shared/components/DataTable/DataTable';


export function JobCandidatesAdminScreen() {
  const { jobId } = useParams<'jobId'>() as { jobId: string };
  const { getAllCandidates, getMatchedCandidates, saveMatchedCandidates } = useJobs();
  const [allCandidates, setAllCandidates] = useState<Candidate[]>([]);
  const [matchedCandidates, setMatchedCandidates] = useState<Candidate[]>([]);
  const navigate = useNavigate();

  const actions: Action<GridRow>[] = useMemo(() => [
    { label: 'View', onAction: ({ raw: candidate }) => navigate(`/jobs/${jobId}/candidates/${candidate.uid}`) },
  ], [navigate]);

  function handleMatchedCandidates(newMatchedCandidates: Candidate[]) {
    saveMatchedCandidates(jobId, matchedCandidates, newMatchedCandidates);
    setMatchedCandidates(newMatchedCandidates);
  }

  useEffect(() => {
    getAllCandidates().then(setAllCandidates);
    if (jobId) getMatchedCandidates(jobId).then(setMatchedCandidates);
  }, [getAllCandidates]);


  return (
    <BaseJobCandidatesScreen
      actions={actions}
      candidates={allCandidates}
      setMatched={handleMatchedCandidates}
      matched={matchedCandidates}
    />
  );
}
