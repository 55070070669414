import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import classes from './MainNavigation.module.css';
import logo from 'modules/shared/images/white-logo.svg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useAuth } from 'modules/auth/services/auth.service';


const pages = [
  { title: 'Home', link: '/' },
  // { title: 'Notifications', link: '/notifications' },
  // { title: 'Manage Jobs', link: '/manageJobs' },
  // { title: 'Current Openings', link: '/jobs' },
  // { title: 'Settings', link: '/settings' },
];


export const MainNavigation = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>();
  const { logout } = useAuth();

  const settings = [
    // {title: 'Profile', onClick: () => {}},
    // {title: 'Account', onClick: () => {}},
    // {title: 'Dashboard', onClick: () => {}},
    {title: 'Logout', onClick: logout}
  ];

  function showDrawer() { setIsDrawerOpen(true) }
  function hideDrawer() { setIsDrawerOpen(false) }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const itemsList =
    <Box
      sx={{ width: 250 }}
      role='presentation'
      onClick={hideDrawer}
      onKeyDown={hideDrawer}
    >
      <List>
        {pages.map(({title, link}) => (
          <ListItem key={title}>
            <ListItemButton component={Link} to={link}>
              <ListItemText primary={title}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>;

  return (
    <AppBar position='static'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' }}}
          >
            <img src={logo} alt='logo' className={classes.logo}/>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={showDrawer}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor='left'
              open={isDrawerOpen}
              onClose={hideDrawer}
            >
              {itemsList}
            </Drawer>

          </Box>
          <Box
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <img src={logo} alt='logo' className={classes.logo}/>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map(({title, link}) => (
              <Button
                key={link}
                sx={{ my: 2, color: 'primary.contrastText', display: 'block' }}
                component={Link}
                to={link}
              >
                {title}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0}}>
            <Tooltip title='Open settings'>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt='Remy Sharp' src='/static/images/avatar/2.jpg'/>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(({title, onClick}) => (
                <MenuItem key={title} onClick={onClick}>
                  <Typography textAlign='center'>{title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
