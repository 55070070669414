import { useDefinedUser } from 'modules/user/services/user.service';
import { CurrentJobsAdminScreen } from './CurrentJobsScreen.admin';
import classes from './CurrentJobsScreen.module.css';
import { useTheme } from '@mui/system';
import { CurrentJobsEmployerScreen } from './CurrentJobsScreen.employer';


export function CurrentJobsScreen() {
  const { isAdmin } = useDefinedUser();
  const theme = useTheme();

  return (
    <div className={classes.container} style={{ padding: theme.spacing(4, 0, 2) }}>
      { isAdmin ? <CurrentJobsAdminScreen/> : <CurrentJobsEmployerScreen/> }
    </div>
  );
}
