import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Job } from 'modules/jobs/services/job';
import { useJobs } from 'modules/jobs/services/jobs.service';
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import classes from './JobPanel.module.css';
import { Section } from './Section';
import CircularProgress from '@mui/material/CircularProgress';
import { format } from 'date-fns';

type TimeRange<T extends Date > = { start: T | undefined, end: T | undefined };

function JobSection(props: ComponentPropsWithoutRef<typeof Section>) {
  return <Section {...props} className={classes.section}/>
}

const defaultJobSlots = ['N/A', 'N/A', 'N/A', 'N/A', 'N/A', 'N/A', 'N/A'];

const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export function JobPanel() {
  const { getJobById } = useJobs();
  const { jobId } = useParams<'jobId'>();
  const [job, setJob] = useState<Job>();
  const [jobSlots, setJobSlots] = useState(defaultJobSlots);

  function getJobslots(job?: Job): string[] {
    console.log(job?.weekRanges);
    return job?.weekRanges?.map(({ dayRanges }) =>
      dayRanges.length ? dayRanges.map(formattedTimeRange).join('\n') : 'N/A'
    ) ?? [];
  }
   function formattedTimeRange(range?: TimeRange<Date>) {
    if (!range) return '';
    const start = range.start ? format(range.start, 'hh:mm a') : 'N/A';
    const end = range.end ? format(range.end, 'hh:mm a') : 'N/A';
    return start + ' - ' + end;
  }
  useEffect(() => {
    if (jobId) getJobById(jobId).then(setJob);
  }, [jobId, getJobById]);

  useEffect(() => {
    if (job) {
      let slots = getJobslots(job);
      setJobSlots(slots );
    }
  }, [job]);

  return (
    <div className={classes.container}>
      <div className={classes.panelWrapper}>
        <div className={classes.header}>
          <Typography variant='h6' sx={{color: 'mainBackground.contrastText', ml: 2}}>Opening Information</Typography>
          { !job && <CircularProgress className={classes.progress} size={20}/> }
        </div>
        <Paper className={classes.panel} sx={{alignSelf: 'flex-end'}}>
          {<>
            <JobSection title='Job Title'>{job ? job.title : '--'}</JobSection>
            <JobSection title='Location'>{job ? job.location : '--'}</JobSection>
            <JobSection title='Pay Range'>{job ? `${job.minHourlyWage} - ${job.maxHourlyWage}/hr` : '--'}</JobSection>
            <JobSection title='Description'>{job ? job.description : '--'}</JobSection>
            <JobSection title='Date Posted'>{job ? job.dateCreated.toLocaleDateString('en-US') : '--'}</JobSection>
            <JobSection title='Job Type Selected'>{job?.jobtypes.map(j => j.name).join(', ') ?? '--'}</JobSection>
            <JobSection title='Industries Selected'>{job?.industries.map(i => i.name).join(', ') ?? '--'}</JobSection>
            <JobSection title='Number of Openings'>{job ? job.openings : '--'}</JobSection>
            <JobSection title='Start and End Date'>{
              job ?
                job.firstDay.toLocaleDateString('en-US') +
                ' - ' +
                (job.lastDay?.toLocaleDateString('en-US') ?? 'Long Term')
              : '--'
            }</JobSection>
          </>
          }
          <Typography variant='subtitle2' sx={{fontWeight: 'bold', mt: 2}}>Job time slots</Typography>
          {weekDays.map((name, i) =>
            <div key={name} className={classes.column_item}>
              <Typography variant='body2' component='div'>
                {name} {jobSlots[i]}
              </Typography>
            </div>
          )}
        </Paper>
      </div>
    </div>
  )
}