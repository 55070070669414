import { EmployersAdminScreen } from './EmployersScreen.admin';
import { useTheme } from '@mui/system';
import classes from './EmployersScreen.module.css';


export function EmployersScreen() {
  const theme = useTheme();

  return (
    <div className={classes.container} style={{padding: theme.spacing(4, 0, 2)}}>
      <EmployersAdminScreen/>
    </div>
  );
}
