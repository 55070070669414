import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Control, Controller, Path, UseFormRegister } from 'react-hook-form';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import ClearIcon from '@mui/icons-material/Clear';


type Props<T> = {
  register: UseFormRegister<T>;
  control: Control<T, any>;
  name: Path<T>;
  error?: boolean;
  helperText?: string;
  label?: string;
  clearable?: boolean;
};


export function DateField<T>({ register, control, name, error, helperText, label, clearable }: Props<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <>{
        <MobileDatePicker
          label={label}
          inputFormat='MM/dd/yyyy'
          {...register(name)}
          value={field.value ?? ''}
          onChange={() => {}}
          onAccept={field.onChange}
          renderInput={params => <TextField
            {...params}
            error={error}
            className='dateField'
            helperText={helperText}
            InputProps={{
              endAdornment: clearable &&
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='Clear'
                    onClick={() => field.onChange(undefined)}
                    edge='end'
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
            }}
          />}
        />
      }</>}
    />
  );
}